
import { defineComponent, ref } from 'vue'
import TmAlert from '@/components/shared/TmAlert.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmTabs from '@/components/shared/TmTabs.vue'
import PageContent from '@/components/layout/pageContent/PageContent.vue'
import type { Tab } from '@/definitions/shared/types'
import { useModes } from '@/compositions/modes'

export default defineComponent({
  components: {
    TmAlert,
    TmButton,
    TmTabs,
    PageContent,
  },
  setup() {
    const { isLoadingMode } = useModes()
    const tab = ref()
    const tabs = ref<Tab[]>([
      {
        name: 'text-messages',
        label: 'Text messages',
        to: { name: 'base.reporting.overview.textMessages' },
      },
      {
        name: 'calls',
        label: 'Calls',
        to: { name: 'base.reporting.overview.calls' },
      },
      {
        name: 'live-chats',
        label: 'Live chats',
        to: { name: 'base.reporting.overview.liveChats' },
      },
      {
        name: 'social-channels',
        label: 'Social channels',
        to: { name: 'base.reporting.overview.socialChannels' },
      },
    ])

    return {
      isLoadingMode,
      tab,
      tabs,
    }
  },
})
